import {
  mdiMapMarkerOutline,
  mdiGoogleMyBusiness,
  mdiMapMarkerQuestionOutline,
  mdiMapMarkerMultipleOutline,
  mdiHandshakeOutline,
  mdiStoreCogOutline,
  mdiForum,
  mdiStarBoxOutline,
  mdiStoreClockOutline,
  mdiMulticast,
} from '@mdi/js'

export const menus = {
  local_data: {
    items: {
      locations: {
        icon: mdiMapMarkerOutline,
        route: 'Locations',
      },
      locations_gpb: {
        icon: mdiGoogleMyBusiness,
        route: 'LocationsGmb',
      },
      user_express: {
        icon: mdiMapMarkerQuestionOutline,
        route: 'UserExpress',
      },
      gpb_qa: {
        icon: mdiForum,
        route: 'GmbQa',
      },
      oh_group: {
        icon: mdiStoreClockOutline,
        route: 'OhGroup',
      },
    },
  },
  engage: {
    items: {
      play_collect: {
        icon: mdiStarBoxOutline,
      },
    },
  },
  visibility: {
    items: {
      locators: {
        icon: mdiMapMarkerMultipleOutline,
        route: 'FrontOffices',
      },
      gpb: {
        icon: mdiStoreCogOutline,
        route: 'Accounts',
      },
      presence_management: {
        icon: mdiMulticast,
        route: 'PresenceManagement',
      },
    },
  },
  global: {
    items: {
      clients: {
        icon: mdiHandshakeOutline,
        route: 'Clients',
      },
    },
  },
  gpb_admin: {
    items: {
      accounts: {
        icon: mdiStoreCogOutline,
        route: 'AdminGmb',
      },
    },
  },
}
